@media only screen and (max-width: 1218px) {
    .textarea_field {
        max-width: 598px;
    }
}
@media only screen and (max-width: 914px) {
    .textarea_field {
        max-width: 293px;
    }
}
@media only screen and (max-width: 899px) {
    .textarea_field {
        max-width: 598px;
    }
}
@media only screen and (max-width: 644px) {
    .textarea_field {
        max-width: 293px;
    }
}
